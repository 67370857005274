import { graphql, Link } from 'gatsby';
import React from 'react';
import Layout from '../common/layout';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { ArticleDate } from '../common/ArticleDate';
import { SEO } from '../common/seo';

const PostPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & a {
    text-decoration: none;
  }
`;

const ArticleTitle = styled.h2`
  margin-bottom: 5px;
  text-align: center;
  font-family: var(--font-title);
`;

const Content = styled.div`
  max-width: 960px;
  margin-top: 40px;

  ${down('sm')} {
    margin-top: 20px;
  }
`;

export default ({ data }) => {
  return (
    <>
      <SEO title="Home" />
      <Layout>
        <Content>
          {data.allMdx.nodes.map(({ id, frontmatter }) => (
            <PostPreview key={id}>
              <ArticleTitle>
                <Link to={`blog${frontmatter.slug}`}>{frontmatter.title}</Link>
              </ArticleTitle>

              <ArticleDate timestamp={frontmatter.timestamp} date={frontmatter.date} />
            </PostPreview>
          ))}
        </Content>
      </Layout>
    </>
  );
};

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { published: { eq: true } } }) {
      nodes {
        id
        timeToRead
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          timestamp: date
          slug
        }
      }
    }
  }
`;
